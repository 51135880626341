import React from 'react';
  
const AnimatedE = (props) => (
  <svg id="thebigE" className={`logo-e ${props.eclass}`} viewBox="0 0 48.61 73.06">
    <title>Ideal Logo E</title>
    <g>
        <polygon id="frontBottom" points="10.63 55.12, 10.63 73.06, 48.61 71.85, 48.61 59.86, 10.63 55.12" fill="#8DC8E8"/>
        <polygon id="sideBottom" points="0.00 61.82, 10.63 55.12, 10.63 73.06, 0.00 70.51, 0.00 61.82" fill="#73AFCA"/>
      
        <polygon id="sideMiddle" points="0.00 44.65, 10.63 27.92, 10.63 45.15, 0.00 53.34, 0.00 44.65" fill="#00608B"/>
        <polygon id="frontMiddle" points="32.34 33.98, 10.63 27.92, 10.63 45.15, 32.34 49.17, 32.34 33.98" fill="#0076A8"/>
    
        <polygon id="frontTop" points="48.61 17.95, 10.63 0.00, 10.63 17.23, 48.61 30.56, 48.61 17.95" fill="#00426A"/>
        <polygon id="sideTop" points="0.00 26.45, 10.63 0.00, 10.63 17.23, 0.00 35.13, 0.00 26.45" fill="#00304D"/>

    </g>
  </svg>
);

export default AnimatedE;
